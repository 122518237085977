.printing-section,
.project-block {
    
    .swiper-button-prev {
        position: absolute;
        left: 0px;
        z-index: 1;
        width: 46px;
        height: 45px;
        font-weight: 600;
        line-height: 46px;
        text-align: center;
        font-size: var(--font-18);
        font-family: "Flaticon";
        color: var(--color-seven);
        border-radius: 50px 50px 50px 0px;
        background-color: var(--white-color);
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        transition: all 500ms ease;
        @media (max-width: 991px) {
            left: 10px;
        }
        &:hover {
            color: white;
            &::before {
                opacity: 1;
                transform: scale(1, 1);
            }
        }
        &:after {
            font-size: 25px !important;
        }
        &:before {
            position: absolute;
            content: "";
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
            opacity: 0;
            z-index: -1;
            transform: scale(0.5, 0.5);
            transition: all 500ms ease;
            border-radius: 50px 50px 50px 0px;
            background: linear-gradient(to right, var(--color-seven) 0%, var(--color-eight) 100%);
        }
    }
    .swiper-button-next {
        position: absolute;
        right: 0px;
        z-index: 1;
        width: 46px;
        height: 45px;
        font-weight: 600;
        line-height: 46px;
        text-align: center;
        font-size: var(--font-18);
        font-family: "Flaticon";
        color: var(--color-seven);
        border-radius: 50px 50px 50px 0px;
        background-color: var(--white-color);
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        font-family: "Flaticon";
        transition: all 500ms ease;
        @media (max-width: 991px) {
            right: 10px;
        }
        &:hover {
            color: white;
            &::before {
                opacity: 1;
                transform: scale(1, 1);
            }
        }
        &:after {
            font-size: 25px !important;
        }
        &:before {
            position: absolute;
            content: "";
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
            opacity: 0;
            z-index: -1;
            transform: scale(0.5, 0.5);
            transition: all 500ms ease;
            border-radius: 50px 50px 50px 0px;
            background: linear-gradient(to right, var(--color-seven) 0%, var(--color-eight) 100%);
        }
    }
    .swiper-wrapper{
    // overflow: hidden;

    }
}
.project-block {
    position: relative;
    margin-bottom: var(--margin-bottom-30);
    .swiper{
        padding-left: 10px;
        padding-right: 10px;
    }
    @media (min-width: 1140px) {
        .swiper {
            padding-left: 100px;
            padding-right: 100px;
        }
    }
}

.project-block .inner-box {
    position: relative;
    overflow: hidden;
}

.project-block .image {
    position: relative;
    overflow: hidden;
    background-color: var(--color-thirtyfour);
}

.project-block .image img {
    position: relative;
    width: 100%;
    height: 500px;
    object-fit: cover;
    display: block;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.project-block .inner-box:hover .image img {
    opacity: 0.7;
    transform: scale(1.05, 1.05) rotate(2deg);
}

.project-block .post-number {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 55px;
    height: 55px;
    z-index: 1;
    font-weight: 500;
    line-height: 55px;
    text-align: center;
    font-size: var(--font-18);
}

.project-block .post-number:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: -1;
    border-radius: 50px 0px 50px 50px;
    background-color: var(--white-color);
}

.project-block .content {
    position: absolute;
    left: 20px;
    bottom: -50px;
    right: 50px;
    opacity: 0;
    padding: 20px 25px;
    border-radius: 4px;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    background-color: var(--white-color);
    transform: scale(1, 0.3);
}

.project-block .inner-box:hover .content {
    opacity: 1;
    bottom: 20px;
    transform: scale(1, 1);
}
.FAQsPanel {
    .collapsable {
        transition: all 0.5s ease;
        overflow: hidden;
        &.nonactive {
            padding: 0px;
            max-height: 0px;
        }
        &.active {
            max-height: 100px;
        }
    }
}
