@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./assets/scss/theme.scss");
@import url("./assets/scss/carosel.scss");

.headanim {
    animation: anim 5s linear infinite;
}

.theme-btn {
    cursor: pointer;
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}

@keyframes anim {

    /* from {transform: translateY(100px);}
    to {transform: translateY(-100px);} */
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(20px);
    }
}

/* Btn Style One */


.btn-style-one {
    position: relative;
    font-weight: 500;
    overflow: hidden;
    text-align: center;
    display: inline-flex;
    align-items: center;
    padding: 16px 35px;
    color: var(--white-color);
    font-size: var(--font-14);
    text-transform: capitalize;
    background-color: var(--main-color);
    font-family: var(--font-family-Outfit);

    background: var(--color-seven);
    background: linear-gradient(to right, var(--color-seven) 0%, var(--color-eight) 100%);

    .icon {
        position: relative;
        z-index: 1;
        font-weight: normal;
        font-family: "flaticon";
        font-size: var(--font-16);
        transition: all 0.3s ease;
        margin-left: var(--margin-left-15);
    }

    .btn-wrap {
        position: relative;
        z-index: 1;
        float: left;
        overflow: hidden;
        display: inline-block;

        .text-two {
            position: absolute;
            top: 100%;
            display: block;
            color: var(--white-color);
            transition: all 0.3s ease;
        }

        .text-one {
            position: relative;
            display: block;
            color: var(--white-color);
            transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
        }
    }

    &:before {
        transition-duration: 800ms;
        position: absolute;
        width: 200%;
        height: 200%;
        content: "";
        top: -200%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        z-index: 1;

        background: var(--color-eight);
        background: linear-gradient(to right, var(--color-eight) 0%, var(--color-seven) 100%);
    }

    &:hover {
        .btn-wrap {
            .text-one {
                &:first-child {
                    -webkit-transform: translateY(-150%);
                    -ms-transform: translateY(-150%);
                    transform: translateY(-150%);
                }
            }

            .text-two {
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                color: var(--white-color);
                transform: translateY(-50%);
            }
        }

        .icon {
            color: var(--white-color);
        }

        &:before {
            top: -40%;
        }

    }
}


.printing-section {
    .title-box {
        position: relative;
        font-weight: 400;
        z-index: 1;
        line-height: 1.2em;
        text-align: center;
        // font-size:var(--font-100);
        margin-bottom: var(--margin-bottom-70);
        font-family: var(--font-family-Montaga);

        span {
            color: var(--color-seven);
            background: linear-gradient(to left, var(--color-seven) 0%, var(--color-eight) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        i {
            display: block;
            position: relative;
            font-style: normal;
            font-size: var(--font-122);
            color: var(--color-fourteen);
        }
    }
}

.title-box h2:before {
    position: absolute;
    content: '';
    left: 0px;
    right: 0px;
    bottom: 5px;
    height: 5px;
    // z-index: -1;
    background: url(./assets/images/icons/vector-10.png) center bottom no-repeat
}

.headerHeight {
    min-height: calc(100vh - 100px);
}

.button {
    background: black;

    .arrow {
        position: relative;
        z-index: 1;
        font-weight: normal;
        font-family: "flaticon";
        transition: all 0.3s ease;
    }
}

.st0 {
    display: none;
    fill: #FFFFFF;
    stroke: #000000;
    stroke-miterlimit: 10;
}

.st1 {
    fill: #FFFFFF;
}

.st2 {
    fill: #EFD6AC;
    mix-blend-mode: darken;
    opacity: 0;
    animation: myFadeAnimation .75s linear 0s 1 forwards;

}

.st3 {
    fill: #536d6a;
    mix-blend-mode: darken;
    animation: myFadeAnimation .75s linear 0.5s 1 forwards;
    opacity: 0;
}

.st4 {
    fill: #C34C27;
    animation: myFadeAnimation .75s linear 1s 1 forwards;
    opacity: 0;
    mix-blend-mode: darken;
}

.st5 {
    animation: myFadeAnimation .75s linear 1.5s 1 forwards;
    opacity: 0;
}

@keyframes myFadeAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
input, textarea{
    font-family: Outfit, sans-serif !important;
}
.cards{
    font-family: Outfit, sans-serif !important;
}